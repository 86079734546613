import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue2({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Freelancing, finances, tech and more"
            pageSocialImage=""
            pageTitle="Issue #2"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #2"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "I’d like to start by saying thanks to everyone who messaged me about the new newsletter – it seems you’re all enjoying it and I hope it evolves to become more insightful as time goes on.", type: "paragraph"},
                        {spans: [], text: "With that being said, January was a very busy month for me so I have a lot to share (let me know if you prefer the more detailed posts).", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at January"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "Heading into January, I was hoping for a bit of a relaxed month – a few days a week on a contract project and a day or two for some other projects – that didn’t go to plan.", type: "paragraph"},
                        {spans: [], text: "I ended up doing a bunch of overtime for a client I worked for last year (which I hadn’t planned for) and more overtime for a new project that I couldn’t fit in during the week.", type: "paragraph"},
                        {spans: [], text: "The contract project, I’ve been working on since November (3 days a week), is now finished. But they’re keen to work with me again when my schedule clears up.", type: "paragraph"},
                        {spans: [], text: "In total, I worked on projects for 5 different clients in January and received 7 new project enquiries (I actively pursued 3 of them).", type: "paragraph"},
                        {spans: [], text: "💵 Finances", type: "heading4"},
                        {spans: [], text: "I’ve also been looking into my finances this month. They’re in good shape, but with the extra money I’m earning, I need a plan for what I do with it.", type: "paragraph"},
                        {spans: [{
                            start: 108,
                            end: 133,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.ramseysolutions.com/dave-ramsey-7-baby-steps",
                            },
                        }], text: "I’ll be doing a more detailed post about this, but at a high level, I plan to follow something in line with Dave Ramseys 7 baby steps.", type: "paragraph"},
                        {spans: [{
                            start: 61,
                            end: 68,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://getpenfold.com/",
                            },
                        }], text: "At the beginning of January I also opened up an account with Penfold to manage my pension. The main reason for going with Penfold is because they’re solely focused on providing pensions for freelances.", type: "paragraph"},
                        {spans: [], text: "🖥 Office upgrades", type: "heading4"},
                        {spans: [], text: "For the past 8 years I’ve been using my 15” MacBook Pro for pretty much everything I do. I was holding out for an update to the 16” MacBook Pro, but I decided to upgrade now.", type: "paragraph"},
                        {spans: [], text: "I decided to invest in the base model Mac Mini and the LG 27” Ultra Fine 5K display. It’s just as well because a week after getting my Mac Mini my MacBook Pro died and no longer turns on.", type: "paragraph"},
                        {spans: [], text: "A gentle reminder that you don’t need the latests and greatest tech to get started as a freelancer – I’d argue to save your money for cash flow purposes and use the tech you’ve got.", type: "paragraph"},
                        {spans: [], text: "🎗 Charity", type: "heading4"},
                        {spans: [{
                            start: 61,
                            end: 82,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://homelessoxfordshire.uk/",
                            },
                        }], text: "Mid-January, I decided to setup a donation for £10 a month to Homeless Oxfordshire. As the name suggests they help homeless people in Oxfordshire (where I live). It’s a small amount of money but even a little can help.", type: "paragraph"},
                        {spans: [{
                            start: 43,
                            end: 50,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://ecologi.com/robsimpson",
                            },
                        },{
                            start: 206,
                            end: 268,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://ecologi.com/robsimpson?r=5f521bb5f7b2880018219050",
                            },
                        }], text: "January also marks 5 months of donating to Ecologi (it’s a monthly subscription where they plant trees to help offset your carbon footprint). So far I’ve planted 190 trees. If you’d like to signup, you can use my link and they’ll plant an extra 30 trees for both of us.", type: "paragraph"},
                        {spans: [], text: "🌮 Food waste", type: "heading4"},
                        {spans: [], text: "My wife and I have also started taking steps towards reducing food waste. We came across a community fridge in our local area who’ve been setup with the sole intention of reducing food waste. They work with local supermarkets, restaurants etc. who donate food that would otherwise be thrown away.", type: "paragraph"},
                        {spans: [{
                            start: 38,
                            end: 52,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://toogoodtogo.co.uk/en-gb",
                            },
                        }], text: "We’ve also started making more use of Too Good To Go, where we get a box of food from our local Morrisons for around £3. Too Good To Go partner up with loads of different companies, so you’d probably be able to make use of it where you are too.", type: "paragraph"},
                        {spans: [], text: "📈 Switching to Plausible", type: "heading4"},
                        {spans: [], text: "I’d been thinking about removing Google Analytics (GA) from my website for a while now. Before I could, I saw a tweet by David Fox about moving over to Plausible – a lightweight, privacy-friendly alternative to GA.", type: "paragraph"},
                        {spans: [{
                            start: 65,
                            end: 74,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://plausible.io/",
                            },
                        }], text: "It took me all of 10 minutes to remove GA from my site and setup Plausible. Since making the switch 10 days ago, I’ve used it way more than GA. It provides fewer metrics but it’s also easier to find what matters.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 January goals"
                    checkList={[
                        {complete: true, text: "💰 Earn £7,000"},
                        {complete: false, text: "⚖️ Weigh 184lbs (-6lbs🔻)"},
                        {complete: false, text: "📙 Listen to I’ll teach you to be rich"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to February"
                    content={[
                        {spans: [], text: "I was in 2 minds about taking February easy or not. Then some interesting projects came my way that I couldn’t say no to, so February is yet again going to a busy month.", type: "paragraph"},
                        {spans: [], text: "Week one will be finishing off design work for an accessibility project. I’ve redesigned some of the key pages on their website and need to make design changes to their user dashboards.", type: "paragraph"},
                        {spans: [], text: "Week two-four will mostly be building a new Gatsby, Prismic project for an agency in the UK. There will also be a sprinkling of other projects slotted in each week for the agency I used to work with before I went freelance (these are 2 of the leads that came in during January).", type: "paragraph"},
                        {spans: [], text: "Another reminder, if you’re working a full-time job and hope to go freelance one day, do good work now and don’t burn any bridges, it can work in your favour down the line.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 February goals"
                    checkList={[
                        {complete: false, text: "✍️ Finish writing content for my new website"},
                        {complete: false, text: "🏋️ Exercise 5 days a week"},
                        {complete: false, text: "📙 Read/take action on reading for 25 minutes a day, 3 days a week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Figure out what’s the biggest thing holding back your freelance career and tackle it head-on. For me, I was scared to jump on a call with clients (silly I know). I’d always avoid it and try to operate through email. If I hadn’t tackled that fear I wouldn’t be writing this newsletter today."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/8518bd71-4415-4234-918a-64f23f1090b9_jan-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £7.5k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/8b7cc11e-984b-4a6d-96c8-8320d7a4b13d_black%2Bblum-water-jug.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "BLACK + BLUM Carafe with Charcoal Filter", type: "heading4"},
                        {spans: [], text: "Water has always been my drink of choice. Since working from home, I‘ve been drinking tap water. At the time I hadn’t realised just how bad tap water tasted, then I got the BLACK + BLUM jug and charcoal filter.", type: "paragraph"},
                        {spans: [], text: "Water tastes so much better, plus I’m not having to throw away plastic bottles or plastic water filters in an attempt to get clean drinking water (the charcoal stick also adds minerals to the water as an added bonus).", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/gp/product/B00IN6X88Y/",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/a816b49e-8d63-4535-8135-7a4c88578149_book-cash-money-freelancing.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Cash Money Freelancing by Tom Albrighton", type: "heading4"},
                        {spans: [], text: "I’m only a couple of chapters in but I‘ve decided to take it slow and try to take action on what’s said. From what I’ve read so far, it seems to be a primer for people looking to go freelance or who want to improve their business.", type: "paragraph"},
                        {spans: [], text: "My main bit of advice to anyone would be to focus on taking action over just consuming information. Most of your success will come from the actions you take – but don’t neglect learning new things, finding that balance is key.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/gp/product/1838054553/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue2;
